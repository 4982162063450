import React from 'react'
import GoogleMapReact from 'google-map-react'
import { FaMapMarkerAlt } from 'react-icons/fa'

const MapMarker = ({ lat, lng }) => (
  <FaMapMarkerAlt
    size='42'
    color='#4593d8'
    lat={lat}
    lng={lng}
    style={{ transform: `translate(-50%,-100%)` }}
  />
)

const GoogleMap = ({ content }) => (
  <GoogleMapReact
    bootstrapURLKeys={{
      key: process.env.GATSBY_GOOGLE_MAP_KEY
    }}
    defaultCenter={{
      lat: content.lat,
      lng: content.lng
    }}
    defaultZoom={content.zoom}
  >
    <MapMarker lat={49.793558} lng={18.270462} />
  </GoogleMapReact>
)

GoogleMap.defaultProps = {
  lat: 49.793558,
  lng: 18.270462,
  zoom: 17
}

export default GoogleMap
