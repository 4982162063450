import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const HeroBlock01 = ({
  content: { text = [], buttons, form },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: `center`,
        justifyContent: `center`
      }}
    >
      <Box
        sx={{
          textAlign: [`center`, null, null, `center`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <ContentText sx={{ marginTop: 375 }} content={text} />
        </Reveal>
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(HeroBlock01)
