import React from 'react'
import { graphql } from 'gatsby'
import { Box, Card, Container, IconButton, Text, Link } from 'theme-ui'
import Layout from '../@elegantstack/solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '../@elegantstack/solid-ui-blocks/Hero/Block01'
import Features from '../@elegantstack/solid-ui-blocks/Features/Block05'
import TopFeatures from '../@elegantstack/solid-ui-blocks/Features/Block07'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import GoogleMap from '../@elegantstack/solid-ui-components/ContentMap'
import ContentText from '@solid-ui-components/ContentText'
import { FaEnvelope, FaLocationArrow, FaPhone } from 'react-icons/fa'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  return (
    <Layout theme={theme} {...props}>
      <Seo title='Klimatizace a vzduchotechnika' />
      <Header content={content['hlavicka']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>

      <Container id={`sluzby`} variant='full' sx={{ textAlign: `center` }}>
        <Divider space={-5} />
        <ContentText
          content={[
            {
              text: `Váš partner v oblasti **vzduchotechniky** a **klimatizací** již od roku 2000`,
              variant: `h2`
            }
          ]}
        />
      </Container>

      <Divider space={5} />

      <Container variant='wide' sx={styles.featuresContainer}>
        <Divider space={-6} />
        <TopFeatures content={content['sluzby']} />
        <Divider space='4' />
        <Features content={content['dalsi-sluzby']} />
      </Container>

      <Divider space='5' />

      <Container id={`kontakt`} variant='full' sx={{ textAlign: `center` }}>
        <ContentText content={content['kontakt']['text']} />
        <Box
          sx={{
            display: `block`,
            flex: 1,
            position: `relative`,
            overflow: `hidden`,
            height: [1250, 1250, 1250, 600],
            width: [`100%`]
          }}
        >
          <Box
            sx={{
              position: [null, null, null, `absolute`],
              zIndex: [null, null, null, 1],
              top: [null, null, null, 50],
              right: [null, null, null, 100],
              display: `flex`,
              flexDirection: [`column`, `column`, `column`, `row`],
              gap: 3
            }}
          >
            <Card
              variant='paper'
              sx={{
                width: [null, null, null, 300]
              }}
            >
              <ContentText
                content={[
                  {
                    text: `Jednatel`,
                    variant: `h6`
                  },
                  {
                    text: `Tomáš Freisler`
                  }
                ]}
              />
              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaPhone />
                </IconButton>
                <Link
                  href='tel:+420608877008'
                  variant={'normal'}
                >{`+420 608 877 008`}</Link>
              </Text>

              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaEnvelope />
                </IconButton>

                <Link
                  href='mailto:tomas.freisler@kfklima.cz'
                  variant={'normal'}
                >{`tomas.freisler@kfklima.cz`}</Link>
              </Text>
            </Card>

            <Card
              variant='paper'
              sx={{
                width: [null, null, null, 300]
              }}
            >
              <ContentText
                content={[
                  {
                    text: `Kancelář`,
                    variant: `h6`
                  },
                  {
                    text: `Iveta Schikorová`
                  }
                ]}
              />
              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaPhone />
                </IconButton>
                <Link
                  href='tel:+420737458173'
                  variant={'normal'}
                >{`+420 737 458 173`}</Link>
              </Text>

              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaEnvelope />
                </IconButton>

                <Link
                  href='mailto:info@kfklima.cz'
                  variant={'normal'}
                >{`info@kfklima.cz`}</Link>
              </Text>
            </Card>
          </Box>

          <Box
            sx={{
              position: [null, null, null, `absolute`],
              zIndex: [null, null, null, 1],
              top: [null, null, null, 250],
              right: [null, null, null, 100],
              display: `flex`,
              flexDirection: [`column`, `column`, `column`, `row`],
              gap: 3
            }}
          >
            <Card
              variant='paper'
              sx={{
                width: [null, null, null, 300]
              }}
            >
              <ContentText
                content={{
                  text: `Provozovna`,
                  variant: `h6`
                }}
              />
              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaLocationArrow />
                </IconButton>
                {`Hasičská 776/2`}
                <br />
                {`Ostrava Hrabůvka`}
                <br />
                {`700 30`}
              </Text>

              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaLocationArrow />
                </IconButton>
                <Link
                  href={`https://www.google.com/maps/place/KF+klima+s.r.o./@49.7935576,18.2682732,17z/data=!3m1!4b1!4m5!3m4!1s0x4713e6b6b4d9218d:0x20c3e69cd7c847d7!8m2!3d49.7935124!4d18.2704703`}
                  target={'_blank'}
                >{`49.7936077, 18.2354427`}</Link>
              </Text>
            </Card>

            <Card
              variant='paper'
              sx={{
                width: [null, null, null, 300]
              }}
            >
              <ContentText
                content={{
                  text: `Fakturační adresa`,
                  variant: `h6`
                }}
              />
              <Text>
                <IconButton variant='simple' role='presentation'>
                  <FaLocationArrow />
                </IconButton>
                {`KF klima s.r.o.`}
                <br />
                {`Ke skalce 2004/15`}
                <br />
                {`Ostrava Poruba`}
                <br />
                {`708 00`}
                <br />
                {`IČO: 26826062`}
                <br />
                {`DIČ: CZ 26826062`}
              </Text>
            </Card>
          </Box>

          <GoogleMap content={content['kontakt']['map']} />
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default IndexPage
